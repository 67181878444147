<template>
  <div class="content gradient">
    <article class="row text-center"
    >

      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (8am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />

      <section class="text-center col-lg-8 col-sm-12">

        <h1 style="margin-top: 10px;font-weight: normal">
          TULUM
          <br>BEACH CLUBS
        </h1>


        <p style="font-size: 0.8rem;margin-top: -25px"> Lose no Time, The Beach Await !</p> <br>

        <br>
        <p>
          <strong
            style="font-size: 0.9rem;">
            Make Your Day
            Unforgettable!</strong></p>
        <br>

        <p style="margin-top: -25px">Day parties, Day passes & Beach Club Reservations</p>

        <br>
        <card
          class="card text-center "
          style="
        background: linear-gradient(#23bac4, #66d0dd);
        z-index: 1;width: 90%;margin-left: auto;margin-right: auto;left: 0;right: 0;margin-bottom: 50px;padding: 10px;">
          <p style="font-size: 0.8rem"><strong>Unleash the Magic of Tulum's Blue Beaches</strong>
            <br> Beach Clubs To Spend Your Day In The Best Way</p>


          <br>
          <div style="min-height: 51px">

            <img
              v-for="(row, idx) in venues.slice(0,5)"
              v-bind:alt="row.title"
              v-lazy="row.metadata.main_image.imgix_url + '?fit=clamp&h=50&w=50'"
              height="50px"
              width="50px"
              style="border-radius: 0px"
              v-if="row.metadata.main_image"
            />
          </div>

          <br>
          <br>
          <div class="col-12">
            <RsvpBeachClubsViaDialog></RsvpBeachClubsViaDialog>

          </div>
          <br>


          <br>

          <small style="color: black">
            We operate from 8AM - 11PM / Answer within 15 mins</small
          >
          <p style="font-size: 0.7rem;font-weight: bold">
            Your All-In-One Solution for a Successful Celebration!
          </p>

        </card>

        <p style="max-width: 80%;" class="centered">Tulum's a gem, a hidden treasure,
          <router-link :to="{path: '/blog'}">Explore</router-link> its beauty, beyond all measure.
        </p>
        <img class=" d-none d-sm-inline floating"
          v-lazy="birds"
          height="130px" style="opacity: 0.7;">
        <br>
        <br>

        <div class="row gradient">

          <div
            class="col-sm-12"
            v-for="(row, idx) in venues"
            :key="idx"
            style="
            box-shadow: #000000 10px 10px 2px;
            padding: 30px;
            flex-flow: column;
          "
          >
            <ResponsiveImage :image="row.metadata.main_image.imgix_url" :title="row.title"></ResponsiveImage>
            <br>

            <h5 class="tulum-party-title"
                style="font-size:1.4rem;margin-top: 20px;text-transform: uppercase;text-shadow: black 1px 1px 1px;display:inline;">
              {{ row.title }}
            </h5>
            <br>
            <p
              v-if="clubsConfig[row.slug]">{{ clubsConfig[row.slug].caption }}</p>
            <div
              style="
              background-color:rgba(15, 14, 14, 0.7) !important;
              margin-bottom: 10px;
              padding: 20px;
              line-height: 1.4rem;
              border-radius: 5px;
            "
              class="text-center"
            >

              <div v-html="row.metadata.description"></div>
              <Collapse>
                <CollapseItem title="Details">
                  <div v-html="row.content"></div>
                </CollapseItem>
              </Collapse>
            </div>
            <!--      DESKTOP -->
            <br/>
            <RsvpBeachClubsViaDialog :row="row" :config="clubsConfig[row.slug]"></RsvpBeachClubsViaDialog>
          </div>
        </div>
        <hr>
        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="DAY CLUBS"
                   :primary-color="primaryColor"
        >
          <div class="row ">
            <div class="col-sm-12 text-center centered" style="max-width: 300px">
              <img v-lazy="bgPalm+'?w=300&h=200&fit=clamp'" width="300px" height="200px">
              <br>

              <p style="color: #efefef !important;font-size: 1.2rem;font-weight: bold">Feeling Lost?</p>
              <hr>
              <p style="color: #efefef !important;">
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>


      </section>

      <aside class="card col-4 col-lg-4 d-none d-lg-inline "
      >

        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="DAY CLUBS"
                   :primary-color="primaryColor"

        >
          <div class="row ">
            <div class="col-sm-12 text-center">
              <p style="color: #efefef !important;">Feeling Lost?</p>
              <hr>
              <p style="color: #efefef !important;">
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import beachClubsConfig from '../../common/beachClubsConfig';
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";

const VueWhatsappWidget = () => import( /* webpackChunkName: "vue-whatsapp-widget" */'../../components/WhatsAppWidget/Widget');
const RsvpBeachClubsViaDialog = () => import( /* webpackChunkName: "RsvpDialogs" */'../../components/Reservations/RsvpBeachClubsViaDialog');

export default {
  name: 'BeachClubs.vue',
  data: () => {
    return {

      showPalms: false,
      links: [
        {
          slug: 'taboo-day-events',
          metadata: {
            text: 'Taboo Tulum Day Events',
            link_to: '/article/taboo-tulum-day-events',
            caption: 'Day Live Shows and Happenings at Taboo',
            image: {
              imgix_url: 'https://imgix.cosmicjs.com/a23dd150-61b1-11ee-bacf-0595d1e10c0d-image.png'
            }
          }
        },
        {
          slug: 'tantra-bohemian-luxury-beach-club',
          metadata: {
            text: 'Tantra Bohemian Luxury Beachclub in Tulum',
            link_to: ' /article/tantra-bohemian-luxury-beachclub-in-tulum',
            caption: 'A jewel option for a bohemian luxury beachside experience',
            image: {
              imgix_url: 'https://imgix.cosmicjs.com/336c7ae0-5fc8-11ef-a492-5bdc7520fe60-Captura-de-pantalla-2024-08-21-081801.png'
            }
          }
        },
        {
          slug: 'affordable-tulum-beachclub',
          metadata:
            {
              text: 'Affordable Tulum Beach Clubs',
              caption: 'How to enjoy the beach on a budget',
              link_to: '/article/affordable-tulum-beach-clubs',
              image: {
                imgix_url: 'https://imgix.cosmicjs.com/8e0e4a10-5fc2-11ef-b5ae-a594bb4a8e67-30861412.jpg'
              }
            }
        }],
      clubsConfig: [{}],
      primaryColor: '#23a6d5',
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      mainImage:
        'https://imgix.cosmicjs.com/47e43010-8bd0-11ef-b5a0-93db72e2be98-The_Sun__Our_Home_Star.jpg',
      birds:
        "https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png"
    };
  },
  metaInfo: () => ({
    title: 'Tulum Beach Clubs · Information & Reservations. Day Parties & Day Pass.',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.tulum.party/tulum-day-beach-clubs',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Tulum · Top Beach Clubs To Spend Your Day',
      },
      {
        name: 'keywords',
        content:
          'Tulum, Day CLub, clubs, beach clubs, Today,  2022, Papaya Playa, Vagalume, Events, Bagatelle, Taboo, Tantra',
      },
      {
        property: 'og:title',
        content: `Tulum Beach & Day Clubs. Information & Reservations.`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'Tulum'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content: `Top Beach Clubs | Tulum Mexico`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/82856090-f18b-11ec-8fb8-5d396858ac9b-photo2022-06-2112-46-08.jpg?h=300&w=300&fit=clamp',
      },
    ],
  }),
  components: {
    ResponsiveImage,
    SmartMenu,
    RsvpBeachClubsViaDialog,
    CollapseItem,
    Collapse,
    VueWhatsappWidget,
  },
  computed: {
    venues: {
      get() {
        return this.$store.state.beach_clubs;
      },
    },
    faqs: {
      get() {
        return this.$store.state.faqs_category;
      },
    },
  },
  created() {
    this.$store.dispatch('getBeachClubs', {start: 0, limit: 12});
    this.$store.dispatch('getFaqsByCategory', 'Clubs');
    window.history.scrollRestoration = 'manual';
    this.clubsConfig = beachClubsConfig;
  },
  updated() {
    // setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    onJoinInstagramClick() {
      window.ga('send', 'event', 'instagram', 'JOIN', 'Main', 5);
      this.$rollbar.info('Instagram join');
    },
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
